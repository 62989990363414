import { Link, Outlet } from "react-router-dom";
import { NAME } from "../data/strings";
import { HashLink } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";


export default function Navbar() {
  return (
    <>
      <div>
        <nav className="flex justify-start items-start hover:text-red">
          <div className="w-full mr-2 lg:mr-32 bg-white bg-opacity-70 backdrop-filter backdrop-blur-lg lg:h-16 rounded-tr-2xl rounded-br-2xl flex justify-between items-center drop-shadow-lg mt-4 lg:mt-8 lg:py-0 py-2">
          {/* <div className="w-full mr-32 bg-zinc-100 backdrop-blur-lg lg:h-16 rounded-tr-2xl rounded-br-2xl flex justify-between items-center drop-shadow-lg mt-4 lg:mt-8 lg:py-0 py-2"> */}
            <div className="lg:ml-16 ml-3">
            <Link to="/" className="flex items-center justify-center"> 
              <img className="w-8 h-8 lg:w-12 lg:h-12" src="./logo.jpg" alt="" />
              <span className="text-2xl lg:text-3xl ml-1 lg:ml-2 mt-1 text-blue font-semibold tracking-[0.1rem]">{NAME}</span></Link>
            </div>
            <div className="">
              <ul className="flex gap-2 lg:gap-10 mr-4 lg:mr-14 uppercase font-thin text-blue text-sm lg:text-2xl">
                <li>
                  <Link className="hover:text-red"  to="/">Home</Link>
                </li>
                <li>
                  <Link className="hover:text-red"  to="/about">About Us</Link>
                </li>
                <li>
                  <HashLink className="hover:text-red"  to="/about#contact">Contact Us</HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <Outlet />
    </>
  );
}
