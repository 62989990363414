import React from 'react'
import { HEADING_HERO_SECTION, NAME} from "../data/strings";
import DownloadButton from './DownloadButton';
import WhyDownloadButton from './WhyDownloadButton';

const Hero = ({ onShowPopup }) => {

  return (
            <div
      className="fade-in w-full h-[400px] lg:h-[660px] py-8 bg-cover mt-8 lg:rounded-3xl rounded-2xl relative"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/new_hero_bg.png')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        objectFit: 'cover',
      }}
    >
        {/* <div className="absolute top-0 left-0 w-full h-full bg-white opacity-50 rounded-md lg:rounded-3xl"></div> */}

      <div className='flex items-center justify-center w-full h-full '>
      <div className="block items-center justify-center text-shadow-lg absolute">
        <h1 className="mb-4 rounded-xl text-6xl lg:text-9xl font-extrabold text-center text-white text-opacity-80  textShadow tracking-[0.3rem]">{NAME}</h1>
        <h2 className="bg-white bg-opacity-35 lg:bg-opacity-40 mx-8
        text-xl lg:text-5xl tracking-[0.1rem] text-center font-semibold text-blue">{HEADING_HERO_SECTION}</h2>
        
        <div className='w-full h-auto  block lg:flex items-center justify-center gap-8 mt-8'>
          <div className='flex items-center justify-center'> <DownloadButton/> </div>
          <div className='flex items-center justify-center'>  <WhyDownloadButton whyDownloadClicked={()=>{onShowPopup(true)}}/></div> 
          </div>
      </div>
      
    </div>

      </div>
      
  )
}
export default Hero;

