import React from "react";
import Explain from "../components/Explain";
import FoundersWord from "../components/FoundersWord";
import ContactUs from "../components/ContactUs";


export default function AboutUs() {
  return (
    <main className="px-6 lg:px-32">


      <section className="text-center mt-8 lg:mt-24 mb-8 ">
        <h2 className="text-[2.5rem] lg:text-[3.5rem] font-bold mb-4 text-blue kalam-bold ">Why Guised Up?</h2>
      </section>

      <Explain />

      <FoundersWord />

      <section id="contact">
        <ContactUs />
      </section>
    </main>
  );
}
