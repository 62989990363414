import React from 'react'
import { feedbacks } from '../data/feedbacks'
import FeedbackItem from './FeedbackItem'

export default function Feedbacks() {

    return (
        <div className="pb-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8 ">
          {feedbacks.map((feedback, index) => (
            <FeedbackItem
              key={index}
              name={feedback.name}
              phase={feedback.phase}
              image={feedback.image}
              message={feedback.message}
            />
          ))}
        </div>
        )
}

