export const feedbacks = [
    {
        name:"User One",
        phase:"phase_1.png",
        image:"user1.png",
        message:"I always used to be alone in my college. I could not vibe with the rest of my batch mates. In a way my batch mates used to make fun of me. I always felt alone. After joining this app I found people who faced the same thing in their college. And after talking with them, I felt so comfortable as if I had known them from years..we found our perfect group and friends for a lifetime. Thank you Guised up!!",
    },
    {
        name:"User Two",
        phase:"phase_2.png",
        image:"user2.png",
        
        message:"I remember when my days in office was not going good. Layoffs were happening everywhere. Even my boss used to shout at me every now and then. I could not speak about it to anyone in my office or around me as they would think I am going to lose my job. When I joined guisedup, I found people who were going through the same thing, and thank God, we could speak our heart out. I was not fired in the crisis, but with my friends from guisedup we got a support and would speak every day. It really helped us in our tough times.",
    },
    {
        name:"User Three",
        phase:"phase_3.png",
        image:"user3.png",
        message:"Every day I am busy from morning 7 to night 12am. I am doing so much. I hardly find any time for my self. But when I joined guised up and met similar people we realised that we hardly care about ourselves. And guess what now all people in our group, we take out 1 hour in a day for ourselves to enjoy our cup of tea , made by us, only for us!!",
    }
]