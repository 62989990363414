import React from 'react';

const FeedbackItem = ({ name, phase, image, message }) => {
  return (
    <div className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-2xl shadow-gray-600 shadow-md p-6 lg:w-[360px]">
      <div className="flex items-center mb-4">
      <div className="flex items-center">
        <img src={`${process.env.PUBLIC_URL}/${phase}`} alt={name} className="w-8 h-8 lg:w-12 lg:h-12 rounded-full mr-4" />
        <img src={`${process.env.PUBLIC_URL}/${image}`} alt={name} className="w-8 h-8 lg:w-12 lg:h-12 rounded-full mr-4 translate-x-[-80%]" />
        </div>
        <div>
          {/* <h3 className="text-lg font-bold">{name}</h3> */}
        </div>
      </div>
      <div className='flex items-start justify-center'>
        
        <p className="text-blue kalam-regular"><span className='text-yellow font-bold text-xl lg:text-2xl mr-2'>&quot;</span>{message}<span className='text-yellow font-bold text-xl lg:text-2xl ml-2'>&quot;</span></p>
        
         </div>
    </div>
  );
};

export default FeedbackItem;
