import React from 'react';
import styles from '../additonal_styles/TermsAndConditions.module.css'

const PrivacyPolicy = () => {
  return (
    <main className="w-full flex items-center justify-center">

    <div className={`${styles.container} text-black bg-white bg-opacity-50 lg:w-[70%] my-10 rounded-lg`}>
      <h3 className={styles.heading1}>PRIVACY POLICY</h3>
      <p className={styles.paragraph}>
        We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be advised the Services are hosted in India. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in India, then through your continued use of the Services, you are transferring your data to India, and you expressly consent to have your data transferred to and processed in India.
      </p>

      <h3 className={styles.heading2}>Company Information</h3>
      <p className={styles.paragraph}>
        UniMaa Software Pvt Ltd ("Company," "we," "us," or "our") operates GuisedUp social media application (the "App"). This Privacy Policy informs you of our policies regarding the collection, use, and disclosure of personal data when you use our App.
      </p>

      <h3 className={styles.heading3}>Information Collection and Use</h3>
      <p className={styles.paragraph}>
        We may collect several types of information when you use our App, including:
      </p>
      <ul className={styles.paragraph}>
        <li>Your device's Internet Protocol address (e.g. IP address)</li>
        <li>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</li>
        <li>The time spent on the Application</li>
        <li>The operating system you use on your mobile device</li>
      </ul>
      <p className={styles.paragraph}>
        The Application does not gather precise information about the location of your mobile device.
      </p>

      <h3 className={styles.heading3}>Personal Data</h3>
      <p className={styles.paragraph}>
        While using our App, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you.
      </p>

      <h3 className={styles.heading3}>Usage Data</h3>
      <p className={styles.paragraph}>
        We may also collect information about how the App is accessed and used.
      </p>

      <h3 className={styles.heading3}>Use of Data</h3>
      <p className={styles.paragraph}>
        We use the collected data for various purposes, including:
      </p>
      <ul className={styles.paragraph}>
        <li>To provide and maintain the App.</li>
        <li>To notify you about changes to our App.</li>
        <li>To provide customer support.</li>
        <li>To gather analysis or valuable information so that we can improve our App.</li>
        <li>To monitor the usage of our App.</li>
      </ul>

      <h3 className={styles.heading3}>Data Sharing</h3>
      <p className={styles.paragraph}>
        We may share your personal information in the following situations:
      </p>
      <ul className={styles.paragraph}>
        <li>With vendors, consultants, and other service providers who need access to such information to carry out work on our behalf.</li>
        <li>With third parties to comply with legal obligations or to protect the rights, property, or safety of the Company, our users, or others.</li>
      </ul>

      <h3 className={styles.heading3}>Artificial Intelligence Usage</h3>
      <p className={styles.paragraph}>
        We may utilize artificial intelligence algorithms to analyze user interactions with the App, including but not limited to content preferences, engagement patterns, and behavior trends. This analysis helps us enhance user experience, personalize content, and improve the performance of the App.
      </p>

      <h3 className={styles.heading3}>Cookie Usage</h3>
      <p className={styles.paragraph}>
        We may use cookies and similar tracking technologies to collect information and improve our services. Cookies are small files stored on your device that contain data about your browsing activity. By using our App, you consent to the use of cookies and similar technologies for the purposes described in this Privacy Policy.
      </p>

      <h3 className={styles.heading3}>Data Protection</h3>
      <p className={styles.paragraph}>
        We are committed to protecting your privacy and complying with applicable data protection laws, including the General Data Protection Regulation (GDPR), the California Consumer Privacy Act (CCPA), and the data protection laws of India [Digital Personal Data Protection Act, 2023]. We implement appropriate technical and organizational measures to ensure the security and confidentiality of your personal data.
      </p>

      <h3 className={styles.heading3}>User Rights</h3>
      <p className={styles.paragraph}>
        Depending on your jurisdiction, you may have certain rights regarding your personal data, including the right to access, correct, or delete your personal information. Please contact us if you wish to exercise any of these rights.
      </p>

      <h3 className={styles.heading3}>Opt-Out Rights</h3>
      <p className={styles.paragraph}>
        You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network.
      </p>

      <h3 className={styles.heading3}>Data Retention Policy</h3>
      <p className={styles.paragraph}>
        The Service Provider will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you'd like them to delete User Provided Data that you have provided via the Application, please contact them at admin@guisedup.com and they will respond in a reasonable time.
      </p>

      <h3 className={styles.heading3}>Children</h3>
      <p className={styles.paragraph}>
        The Service Provider does not use the Application to knowingly solicit data from or market to children under the age of 13. The Application does not address anyone under the age of 13. The Service Provider does not knowingly collect personally identifiable information from children under 13 years of age. In the case the Service Provider discovers that a child under 13 has provided personal information, the Service Provider will immediately delete this from their servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact the Service Provider (admin@guisedup.com) so that they will be able to take the necessary actions.
      </p>

      <h3 className={styles.heading3}>Security</h3>
      <p className={styles.paragraph}>
        The Service Provider is concerned about safeguarding the confidentiality of your information. The Service Provider provides physical, electronic, and procedural safeguards to protect information the Service Provider processes and maintains.
      </p>

      <h3 className={styles.heading3}>Changes</h3>
      <p className={styles.paragraph}>
        This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes to the Privacy Policy by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.
      </p>

      <p className={styles.paragraph}>
        This privacy policy is effective as of 2024-08-08.
      </p>

      <h3 className={styles.heading3}>Your Consent</h3>
      <p className={styles.paragraph}>
        By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.
      </p>

      <h3 className={styles.heading3}>Contact Us</h3>
      <p className={styles.paragraph}>
        If you have any questions regarding privacy while using the Application, or have questions about the practices, please contact the Service Provider via email at admin@guisedup.com.
      </p>
    </div>
    </main>
  );
};

export default PrivacyPolicy;
