import React from "react";

export default function Explain() {
  const listItems = [
    {
      text:  "Our life is not always about the good things……In reality, it comprises of all the emotions that we see around us.\n\n" +
      "Real connections are formed when someone accepts our dark phase….as much…..and more than the happier  and shiny ones…….Everyone is going through some phase of life……be it anything!!\n\n" +
      "Don't we want to connect with people who are in the same phase of life as we are??",
      image: 'first.png',
    },
    {
      text:   "Everyone can cater to you when you are happy and rich,…..but who would actually be with you when you are grinding……hustling…..healing…..struggling……and everything else?\n\n" +
      "Don't you want to connect with real people ,…..form real connection,….real bond with someone, who are exactly facing the same thing as you are??………\n\n" +
      "The world is a tough place to survive……..Won't the world become a little bit easier if you have people going through the same thing  as you are?\n\n" +
      "Our heart is barely keeping up in the race with AI……don't we need a community ,…..a friend,…..a person to call our buddy, our own?\n\n" +
      "Don't we want to connect and form long lasting bonds?",
      image: 'second2.jpeg',
    },
  ];

  return (
    <section className="flex flex-col gap-4 text-[0.8rem] lg:text-3xl text-blue kalam-bold w-[99%] lg:w-full">
    <div className="flex justify-between items-center gap-4 lg:gap-32 mb-6">
    <p className="whitespace-pre-line">{listItems[0].text}</p>
      <img className="w-[160px] lg:w-[460px] bg-white bg-opacity-80 backdrop-filter backdrop-blur-lg h-auto rounded-3xl" src={listItems[0].image} />
    </div>

    <div className="flex justify-between items-center gap-2 lg:gap-32">
      <img  className="w-[160px] lg:w-[460px] bg-white bg-opacity-60 backdrop-filter backdrop-blur-lg h-auto rounded-3xl" src={listItems[1].image} />
      <p className="w-[45%] whitespace-pre-line">{listItems[1].text}</p>
    </div>
  </section>
  );
}
