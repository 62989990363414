import React, { useState } from 'react'
import Hero from '../components/Hero'
import Feedbacks from '../components/Feedbacks'
import Heading from '../components/Heading'
import { FEEDBACKS_SECTION_HEADING } from '../data/strings'
import PopupVideo from '../components/PopupVideo'
import { Link } from 'react-router-dom'

export default function Home() {
  const [showPopup, setShowPopup] = useState(false);

  // if(!showPopup){
  //   setTimeout(()=>{
  //       setShowPopup(true);
  //   }, 5000)
  // }

  return (
    <main className="px-6 lg:px-32">
      <div>
        
      {!showPopup ?(
        
        <div>
          <Hero onShowPopup={(value) => setShowPopup(value)}  />       
        </div>
        
      ):

      <div className='w-full flex items-center justify-center'
       >
        <PopupVideo onClose={() => setShowPopup(false)} />    
        
      </div>}
      

      </div>

      <div>

      <section className="text-center my-8 py-12 bg-cream bg-opacity-70 rounded-xl ">
        <h2 className="text-[2rem] lg:text-[3rem] font-bold mb-4 text-blue kalam-bold">Discover the Story Behind 'Guised Up'</h2>
        <p className="mb-6 kalam-semibold text-blue text-[1.85rem] lg:text-[2.25rem]">Learn why we created 'Guised Up' and what drives us.</p>
        <Link to="/about" className="bg-blue-500 text-white px-6 py-3 rounded-lg transition transform hover:scale-105">
          {/* Explore Our Story */}
          <button className="comic-button">Explore Our Story!</button>
        </Link>
      </section>
 
      </div>
      
        <Heading heading={FEEDBACKS_SECTION_HEADING}/>
        <Feedbacks/>      
        
    </main>

  )
}