import React from 'react';
import { FOUNDER_IMAGE, FOUNDER_NAME, FOUNDER_PARAGRAPH } from '../data/strings';

export default function FoundersWord() {

    

    return (
        <main className='pt-8 lg:pt-32'>

<div className="bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg rounded-3xl shadow-md p-8 md:p-12 lg:p-16">
        <div className="flex flex-col items-center mb-4 md:mb-8 md:flex-row md:justify-center">
            <div className=''>
            <img src={FOUNDER_IMAGE} alt={FOUNDER_NAME} className="w-[200px] h-[200px] rounded-3xl mb-4 md:mb-0 " />
        <div>
            {/* <h3 className="text-red mt-4 text-2xl md:text-2xl lg:text-3xl font-medium">{FOUNDER_NAME}</h3> */}
            <h3 className="text-red mt-4 text-2xl md:text-2xl lg:text-3xl font-medium text-center">Founder's Note</h3>
        </div>
            </div>
        
        </div>
          {/* <h4 className=" text-blue dancing-script-bold text-lg md:text-xl lg:text-2xl mb-2">{FOUNDER_HEADING}</h4> */}
          {/* <p className="kalam-regular text-lg md:text-xl lg:text-2xl leading-relaxed">{FOUNDER_PARAGRAPH}</p> */}
          <p className="text-blue dancing-script-regular text-lg md:text-xl lg:text-3xl">
            <span className='text-green-700 font-bold mr-2'>&quot;</span>
            {FOUNDER_PARAGRAPH}
            <span className='text-green-700 font-bold ml-2'>&quot;</span></p>
        </div>
        </main>

      );
}
