import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import Terms from './pages/Terms';
import Ok from './pages/ok';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {

const imageUrls = [       
'IMG_6751.png',
'IMG_6752.png',
'IMG_6753.png',
'IMG_6754.png',
'IMG_6755.png',
'IMG_6756.png',
'IMG_6757.png',
];

  const getRandomImageName = () => {
    const randomIndex = Math.floor(Math.random() * imageUrls.length);
    return imageUrls[randomIndex];
  };

  return (
    <BrowserRouter>  
    {/* <div className="h-full w-full px-6 lg:px-32 bg-zinc-300 rounded-md"> */}
    <main className='w-full '>

    <div
          className=""
          style={{
            backgroundImage: `url('${process.env.PUBLIC_URL}/bgs/${getRandomImageName()}')`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat', 
            width: '100vw', 
            overflow: 'hidden',
          }}
        >

    {/* IMG_6757.HEIC */}
      <header className="App-header">
        <Navbar/>
      </header>

      <Routes>
          <Route index element={<Home />} />
          <Route exact path="/home" element={<Home/>} />
          <Route path="about" element={<AboutUs/>} />     
          <Route path="terms" element={<Terms/>} />     
          <Route path="privacy-policy" element={<PrivacyPolicy/>} />     
          <Route path="/health" element={<Ok/>}/>
      </Routes>

      <footer>
        <Footer/>
      </footer>
            
    </div>


    </main>
        </BrowserRouter> 
  );
}

export default App;
