import React, { useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { writeDataToFirestore } from "../backend/firebase";

export default function ContactUs() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmit, setFormSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Message:", message);

    const data = {
      name: name,
      email: email,
      message: message
  }
    // saveData(name, email, message);
    const success = writeDataToFirestore(data);

    // Handle the result
    if (success) {
        console.log("Data saved successfully!");
        setFormSubmit(true)
    } else {
        console.log("Failed to save data.");
        setFormSubmit(false)
    }
  };

  return (
    <main className="py-8 lg:py-32">
    
    {!formSubmit ?(  <div
      className="container mx-auto py-16 rounded-3xl relative "
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/contact_bg.png')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        objectFit: "cover"
      }}
    >
      <div>
      {/* <h2 className="text-3xl font-bold text-blue ml-16">Contact Us</h2> */}
      <h2 className="text-3xl font-bold text-blue ml-6 lg:ml-16 mb-8 lg:mb-0 ">
        <FaEnvelope className="inline-block mr-2 mb-1 text-blue" />
        Contact Us
      </h2>
      {/* contact_bg.png */}
      <div className="block lg:flex justify-between items-center ">
        <div className="ml-6 lg:ml-16 flex items-center justify-center">
          <div>
            <img
              src="talk.jpeg"
              alt="Contact"
              className="w-[200px] lg:w-[400px] rounded-3xl "
            />
            <h5 className="text-blue font-normal text-[1rem] mt-4 p-2">Contact us via call or whatsapp for any query on our number <span className="font-semibold">+919108093033</span></h5>
          </div>
          
        </div>
        

        <form
          className="text-2xl font-medium p-8 w-full lg:w-[50%]"
          onSubmit={handleSubmit}
        >
          <div className="mb-4">
            <label htmlFor="name" className="block mb-2 text-blue">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="rounded-md px-4 py-2 w-full cursor-text hover:bg-aq"
              placeholder="Enter your name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block mt-8 mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="border rounded-md px-4 py-2 w-full"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message" className="block mt-8 mb-2">
              Message
            </label>
            <textarea
              id="message"
              className="border rounded-md px-4 py-2 w-full"
              rows="4"
              placeholder="Enter your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className="flex items-end justify-end" >
          <button
            className="relative bg-blue hover:bg-blue text-white rounded-md"
            type="submit"
          >
            <div className="flex items-center py-2 px-4">
              <span className="font-medium">Submit</span>
              <svg
                className="h-9 w-9 ml-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </div>
            <div className="absolute w-full h-2 bg-yellow rounded-bl-md rounded-br-md"></div>
          </button>

          </div>
          
        </form>
      </div>
      </div>

    
  
    </div>):
            // success message that we acknoweledge
            <div className="bg-white bg-opacity-50 text-blue p-8 rounded-xl shadow-md w-full">
    <div className="flex items-center">
      <svg
        className="h-6 w-6 mr-2"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
      <h3 className="font-bold text-lg">Thank you for your message!</h3>
    </div>
    <p className="mt-2">
      We have received your message and we appreciate your feedback.
    </p>
  </div>
      }
    </main>
  );
}
