import React from 'react';

const Heading = ({ heading }) => {
  return (
    <div className="w-full text-start py-8 pt-16">
      <h2 className="inline-block px-4 bg-white bg-opacity-50 backdrop-filter backdrop-blur-lg w-auto text-blue text-3xl font-bold">{heading}</h2>
    </div>
  );
};

export default Heading;
