import React from "react";
import styles from '../additonal_styles/TermsAndConditions.module.css'

export default function Terms() {
  return (
    <main className="w-full flex items-center justify-center">


    
    <div className={`${styles.container} text-black bg-white bg-opacity-50 lg:w-[70%] my-10 rounded-lg`}>
      <div className="p-8">
    <h1 className={styles.heading1}>GuiseD Up</h1>
    <h2 className={styles.heading3}>by</h2>
    <h3 className={styles.heading3}>Unimaa Software Private Limited</h3>
    <h2 className={styles.heading4}>Terms and Conditions</h2>

    <h3 className={styles.heading3}>Agreement to Our Legal Terms</h3>
    <p className={styles.paragraph}>
      We are UNIMAA SOFTWARE PRIVATE LIMITED, doing business as Guised Up
      ("Company", "we", "us", or "our"), a company registered in India at No.
      13-14, 2nd floor, Kothnur Main Rd, opposite Corporation Bank, JP Nagar
      7th Phase, Bengaluru, Karnataka 560078.
    </p>
    <p className={styles.paragraph}>
      We operate the mobile application Guised Up (the "App"), as well as any
      other related products and services that refer or link to these legal
      terms (the "Legal Terms") (collectively, the "Services").
    </p>
    <p className={styles.paragraph}>
      You can contact us at admin@guisedup.com, or by mail to No. 13-14, 2nd
      floor, Kothnur Main Rd, opposite Corporation Bank, JP Nagar 7th Phase,
      Bengaluru, Karnataka 560078, Bengaluru, Karnataka 560078, India.
    </p>
    <p className={styles.paragraph}>
      These Legal Terms constitute a legally binding agreement made between
      you, whether personally or on behalf of an entity ("you"), and UNIMAA
      SOFTWARE PRIVATE LIMITED, concerning your access to and use of the
      Services.
    </p>
    <p className={styles.paragraph}>
      You agree that by accessing the Services, you have read, understood, and
      agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH
      ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
      THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
    </p>
    <p className={styles.paragraph}>
      We will provide you with prior notice of any scheduled changes to the
      Services you are using. The modified Legal Terms will become effective
      upon posting on the “Terms and Condition” section of the app. By
      continuing to use the Services after the effective date of any changes,
      you agree to be bound by the modified terms.
    </p>

    <h3 className={styles.heading3}>User Eligibility</h3>
    <p className={styles.paragraph}>
      You must be at least 16 years old to use the App. By using the App, you
      represent and warrant that you are at least 16 years old.
    </p>
    <p className={styles.paragraph}>
      We recommend that you print a copy of these Legal Terms for your
      records.
    </p>

    <h3 className={styles.heading3}>Table of Contents</h3>
    <ul className={styles.list}>
      <li className={styles.listItem}>1. OUR SERVICES</li>
      <li className={styles.listItem}>2. INTELLECTUAL PROPERTY RIGHTS</li>
      <li className={styles.listItem}>3. USER REPRESENTATIONS</li>
      <li className={styles.listItem}>4. USER REGISTRATION</li>
      <li className={styles.listItem}>5. PURCHASES AND PAYMENT</li>
      <li className={styles.listItem}>6. POLICY</li>
      <li className={styles.listItem}>7. PROHIBITED ACTIVITIES</li>
      <li className={styles.listItem}>8. USER GENERATED CONTRIBUTIONS</li>
      <li className={styles.listItem}>9. CONTRIBUTION LICENSE</li>
      <li className={styles.listItem}>10. GUIDELINES FOR REVIEWS</li>
      <li className={styles.listItem}>11. MOBILE APPLICATION LICENSE</li>
      <li className={styles.listItem}>12. SOCIAL MEDIA</li>
      <li className={styles.listItem}>13. THIRD-PARTY WEBSITES AND CONTENT</li>
      <li className={styles.listItem}>14. ADVERTISERS</li>
      <li className={styles.listItem}>15. SERVICES MANAGEMENT</li>
      <li className={styles.listItem}>16. PRIVACY POLICY</li>
      <li className={styles.listItem}>17. COPYRIGHT INFRINGEMENTS</li>
      <li className={styles.listItem}>18. TERM AND TERMINATION</li>
      <li className={styles.listItem}>19. MODIFICATIONS AND INTERRUPTIONS</li>
      <li className={styles.listItem}>20. GOVERNING LAW</li>
      <li className={styles.listItem}>21. DISPUTE RESOLUTION</li>
      <li className={styles.listItem}>22. CORRECTIONS</li>
      <li className={styles.listItem}>23. DISCLAIMER</li>
      <li className={styles.listItem}>24. LIMITATIONS OF LIABILITY</li>
      <li className={styles.listItem}>25. INDEMNIFICATION</li>
      <li className={styles.listItem}>26. USER DATA</li>
      <li className={styles.listItem}>27. DATA PROTECTION</li>
      <li className={styles.listItem}>
        28. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
      </li>
      <li className={styles.listItem}>29. SMS TEXT MESSAGING</li>
      <li className={styles.listItem}>
        30. CALIFORNIA USERS AND RESIDENTS
      </li>
      <li className={styles.listItem}>31. CHANGES TO TERMS</li>
      <li className={styles.listItem}>32. MISCELLANEOUS</li>
      <li className={styles.listItem}>33. CONTACT US</li>
    </ul>

    <h3 className={styles.heading3}>1. OUR SERVICES</h3>
    <p className={styles.paragraph}>
      The information provided when using the Services is not intended for
      distribution to or use by any person or entity in any jurisdiction or
      country where such distribution or use would be contrary to law or
      regulation or which would subject us to any registration requirement
      within such jurisdiction or country. Accordingly, those persons who
      choose to access the Services from other locations do so on their own
      initiative and are solely responsible for compliance with local laws, if
      and to the extent local laws are applicable.
    </p>
    <p className={styles.paragraph}>
      The Services are not tailored to comply with industry-specific
      regulations, so if your interactions would be subjected to such laws,
      you may not use the Services. You may not use the Services in a way that
      would violate the Gramm-Leach-Bliley Act (GLBA).
    </p>

    <h3 className={styles.heading3}>2. INTELLECTUAL PROPERTY RIGHTS</h3>
      <p className={styles.paragraph}>
        Unless otherwise indicated, the Services are our proprietary property
        and all source code, databases, functionality, software, website
        designs, audio, video, text, photographs, and graphics on the Services
        (collectively, the "Content") and the trademarks, service marks, and
        logos contained therein (the "Marks") are owned or controlled by us or
        licensed to us, and are protected by copyright and trademark laws and
        various other intellectual property rights and unfair competition laws
        of the United States, foreign jurisdictions, and international
        conventions.
      </p>
      <p className={styles.paragraph}>
        The Content and the Marks are provided on the Services "AS IS" for your
        information and personal use only. Except as expressly provided in these
        Legal Terms, no part of the Services and no Content or Marks may be
        copied, reproduced, aggregated, republished, uploaded, posted, publicly
        displayed, encoded, translated, transmitted, distributed, sold,
        licensed, or otherwise exploited for any commercial purpose whatsoever,
        without our express prior written permission.
      </p>
      <p className={styles.paragraph}>
        Provided that you are eligible to use the Services, you are granted a
        limited license to access and use the Services and to download or print
        a copy of any portion of the Content to which you have properly gained
        access solely for your personal, non-commercial use. We reserve all
        rights not expressly granted to you in and to the Services, the Content,
        and the Marks.
      </p>

      <h3 className={styles.heading3}>3. USER REPRESENTATIONS</h3>
      <p className={styles.paragraph}>
        By using the Services, you represent and warrant that: (1) you have the
        legal capacity and you agree to comply with these Legal Terms; (2) you
        are not a minor in the jurisdiction in which you reside; (3) you will
        not access the Services through automated or non-human means, whether
        through a bot, script, or otherwise; (4) you will not use the Services
        for any illegal or unauthorized purpose; and (5) your use of the
        Services will not violate any applicable law or regulation.
      </p>
      <p className={styles.paragraph}>
        If you provide any information that is untrue, inaccurate, not current,
        or incomplete, we have the right to suspend or terminate your account
        and refuse any and all current or future use of the Services (or any
        portion thereof).
      </p>

      <h3 className={styles.heading3}>4. USER REGISTRATION</h3>
      <p className={styles.paragraph}>
        You may be required to register with the Services. You agree to keep
        your password confidential and will be responsible for all use of your
        account and password. We reserve the right to remove, reclaim, or change
        a username you select if we determine, in our sole discretion, that such
        username is inappropriate, obscene, or otherwise objectionable.
      </p>

      <h3 className={styles.heading3}>5. PURCHASES AND PAYMENT</h3>
      <p className={styles.paragraph}>
        We accept the following forms of payment: [List of payment methods
        accepted]. You agree to provide current, complete, and accurate purchase
        and account information for all purchases made via the Services. You
        further agree to promptly update account and payment information,
        including email address, payment method, and payment card expiration
        date, so that we can complete your transactions and contact you as
        needed. Sales tax will be added to the price of purchases as deemed
        required by us. We may change prices at any time. All payments shall be
        in [currency].
      </p>
      <p className={styles.paragraph}>
        You agree to pay all charges at the prices then in effect for your
        purchases and any applicable shipping fees, and you authorize us to
        charge your chosen payment provider for any such amounts upon placing
        your order. If your order is subject to recurring charges, then you
        consent to our charging your payment method on a recurring basis without
        requiring your prior approval for each recurring charge, until you
        notify us of your cancellation.
      </p>
      <p className={styles.paragraph}>
        We reserve the right to correct any errors or mistakes in pricing, even
        if we have already requested or received payment. We also reserve the
        right to refuse any order placed through the Services.
      </p>

      <h3 className={styles.heading3}>6. POLICY</h3>
      <p className={styles.paragraph}>
        [Insert detailed policy information here, including refund, cancellation,
        return, etc. if applicable].
      </p>

      <h3 className={styles.heading3}>7. PROHIBITED ACTIVITIES</h3>
      <p className={styles.paragraph}>
        You may not access or use the Services for any purpose other than that
        for which we make the Services available. The Services may not be used
        in connection with any commercial endeavors except those that are
        specifically endorsed or approved by us.
      </p>
      <p className={styles.paragraph}>
        As a user of the Services, you agree not to:
      </p>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          Systematically retrieve data or other content from the Services to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
        </li>
        <li className={styles.listItem}>
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
        </li>
        <li className={styles.listItem}>
          Circumvent, disable, or otherwise interfere with security-related
          features of the Services, including features that prevent or restrict
          the use or copying of any Content or enforce limitations on the use of
          the Services and/or the Content contained therein.
        </li>
        {/* Add other prohibited activities as needed */}
      </ul>

      <h3 className={styles.heading3}>8. USER GENERATED CONTRIBUTIONS</h3>
      <p className={styles.paragraph}>
        The Services may invite you to chat, contribute to, or participate in
        blogs, message boards, online forums, and other functionality, and may
        provide you with the opportunity to create, submit, post, display,
        transmit, perform, publish, distribute, or broadcast content and
        materials to us or on the Services, including but not limited to text,
        writings, video, audio, photographs, graphics, comments, suggestions, or
        personal information or other material (collectively, "Contributions").
      </p>
      <p className={styles.paragraph}>
        Contributions may be viewable by other users of the Services and through
        third-party websites. As such, any Contributions you transmit may be
        treated as non-confidential and non-proprietary. When you create or make
        available any Contributions, you thereby represent and warrant that:
      </p>
      <ul className={styles.list}>
        <li className={styles.listItem}>
          The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party.
        </li>
        <li className={styles.listItem}>
          You are the creator and owner of or have the necessary licenses,
          rights, consents, releases, and permissions to use and to authorize
          us, the Services, and other users of the Services to use your
          Contributions in any manner contemplated by the Services and these
          Legal Terms.
        </li>
        {/* Add other warranties as needed */}
      </ul>

      <h3 className={styles.heading3}>9. CONTRIBUTION LICENSE</h3>
      <p className={styles.paragraph}>
        By posting your Contributions to any part of the Services or making
        Contributions accessible to the Services by linking your account from
        the Services to any of your social networking accounts, you automatically
        grant, and you represent and warrant that you have the right to grant,
        to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free, fully-paid, worldwide right, and license to
        host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
        retitle, archive, store, cache, publicly perform, publicly display,
        reformat, translate, transmit, excerpt (in whole or in part), and
        distribute such Contributions (including, without limitation, your image
        and voice) for any purpose, commercial, advertising, or otherwise, and
        to prepare derivative works of, or incorporate into other works, such
        Contributions, and grant and authorize sublicenses of the foregoing. The
        use and distribution may occur in any media formats and through any
        media channels.
      </p>
      <p className={styles.paragraph}>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
      </p>
      <p className={styles.paragraph}>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the Services. You are
        solely responsible for your Contributions to the Services and you
        expressly agree to exonerate us from any and all responsibility and to
        refrain from any legal action against us regarding your Contributions.
      </p>
      <p className={styles.paragraph}>

      We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change
any Contributions; (2) to re-categorise any Contributions to place them in more appropriate
locations on the Services; and (3) to pre-screen or delete any Contributions at any time and for
any reason, without notice. We have no obligation to monitor your Contributions.
</p>
<h3 className={styles.heading3}>10. GUIDELINES FOR REVIEWS</h3>
<p className={styles.paragraph}>
We may provide you areas on the Services to leave reviews or ratings. When posting a review,
you must comply with the following criteria: (1) you should have firsthand experience with the
person/entity being reviewed; (2) your reviews should not contain offensive profanity, or abusive,
racist, offensive, or hateful language; (3) your reviews should not contain discriminatory
references based on religion, race, gender, national origin, age, marital status, sexual
orientation, or disability; (4) your reviews should not contain references to illegal activity; (5) you
should not be affiliated with competitors if posting negative reviews; (6) you should not make
any conclusions as to the legality of conduct; (7) you may not post any false or misleading
statements; and (8) you may not organize a campaign encouraging others to post reviews,
whether positive or negative.

We may accept, reject, or remove reviews at our sole discretion. We have absolutely no
obligation to screen reviews or to delete reviews, even if anyone considers reviews
objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent
our opinions or the views of any of our affiliates or partners. We do not assume liability for any
review or for any claims, liabilities, or losses resulting from any review. By posting a review, you
hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable,
and sublicensable right and license to reproduce, modify, translate, transmit by any means,
display, perform, and/or distribute all content relating to review

</p>

<h3 className={styles.heading3}>11. MOBILE APPLICATION LICENSE</h3>
<h3 className={styles.heading3}>Use license</h3>
<p className={styles.paragraph}>


If you access the Services via the App, then we grant you a revocable, non-exclusive,
non-transferable, limited right to install and use the App on wireless electronic devices owned or
controlled by you, and to access and use the App on such devices strictly in accordance with
the terms and conditions of this mobile application license contained in these Legal Terms. You
shall not: (1) except as permitted by applicable law, decompile, reverse engineer, disassemble,
attempt to derive the source code of, or decrypt the App; (2) make any modification, adaptation,
improvement, enhancement, translation, or derivative work from the App; (3) violate any
applicable laws, rules, or regulations in connection with your access or use of the App; (4)
remove, alter, or obscure any proprietary notice (including any notice of copyright or trademark)
posted by us or the licensors of the App; (5) use the App for any revenue-generating endeavour,
commercial enterprise, or other purpose for which it is not designed or intended; (6) make the
App available over a network or other environment permitting access or use by multiple devices
or users at the same time; (7) use the App for creating a product, service, or software that is,
directly or indirectly, competitive with or in any way a substitute for the App; (8) use the App to
send automated queries to any website or to send any unsolicited commercial email; or (9) use
any proprietary information or any of our interfaces or our other intellectual property in the
design, development, manufacture, licensing, or distribution of any applications, accessories, or
devices for use with the App.

</p>

<h3 className={styles.heading3}>Apple and Android Devices</h3>
<p className={styles.paragraph}>


The following terms apply when you use the App obtained from either the Apple Store or Google
Play (each an 'App Distributor') to access the Services: (1) the license granted to you for our
App is limited to a non-transferable license to use the application on a device that utilises the
Apple iOS or Android operating systems, as applicable, and in accordance with the usage rules
set forth in the applicable App Distributor’s terms of service; (2) we are responsible for providing
any maintenance and support services with respect to the App as specified in the terms and
conditions of this mobile application license contained in these Legal Terms or as otherwise
required under applicable law, and you acknowledge that each App Distributor has no obligation
whatsoever to furnish any maintenance and support services with respect to the App; (3) in the
event of any failure of the App to conform to any applicable warranty, you may notify the
applicable App Distributor, and the App Distributor, in accordance with its terms and policies,
may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by
applicable law, the App Distributor will have no other warranty obligation whatsoever with
respect to the App; (4) you represent and warrant that (i) you are not located in a country that is
subject to a US government embargo, or that has been designated by the US government as a
'terrorist supporting' country and (ii) you are not listed on any US government list of prohibited or
restricted parties; (5) you must comply with applicable third-party terms of agreement when
using the App, e.g. if you have a VoIP application, then you must not be in violation of their
wireless data service agreement when using the App; and (6) you acknowledge and agree that
the App Distributors are third-party beneficiaries of the terms and conditions in this mobile
application license contained in these Legal Terms, and that each App Distributor will have the
right (and will be deemed to have accepted the right) to enforce the terms and conditions in this
mobile application license contained in these Legal Terms against you as a third-party
beneficiary thereof.

</p>


<h3 className={styles.heading3}>12. SOCIAL MEDIA</h3>
      <p className={styles.paragraph}>
      As part of the functionality of the Services, you may link your account with online accounts you
have with third-party service providers (each such account, a 'Third-Party Account') by either:
(1) providing your Third-Party Account login information through the Services; or (2) allowing us
to access your Third-Party Account, as is permitted under the applicable terms and conditions
that govern your use of each Third-Party Account. You represent and warrant that you are
entitled to disclose your Third-Party Account login information to us and/or grant us access to
your Third-Party Account, without breach by you of any of the terms and conditions that govern
your use of the applicable Third-Party Account, and without obligating us to pay any fees or
making us subject to any usage limitations imposed by the third-party service provider of the
Third-Party Account. By granting us access to any Third-Party Accounts, you understand that
(1) we may access, make available, and store (if applicable) any content that you have provided
to and stored in your Third-Party Account (the 'Social Network Content') so that it is available on
and through the Services via your account, including without limitation any friend lists and (2) we
may submit to and receive from your Third-Party Account additional information to the extent
you are notified when you link your account with the Third-Party Account. Depending on the
Third-Party Accounts you choose and subject to the privacy settings that you have set in such
Third-Party Accounts, personally identifiable information that you post to your Third-Party
Accounts may be available on and through your account on the Services. Please note that if a
Third-Party Account or associated service becomes unavailable or our access to such
Third-Party Account is terminated by the third-party service provider, then Social Network
Content may no longer be available on and through the Services. You will have the ability to
disable the connection between your account on the Services and your Third-Party Accounts at
any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED
SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. We
make no effort to review any Social Network Content for any purpose, including but not limited
to, for accuracy, legality, or non-infringement, and we are not responsible for any Social Network
Content. You acknowledge and agree that we may access your email address book associated
with a Third-Party Account and your contacts list stored on your mobile device or tablet
computer solely for purposes of identifying and informing you of those contacts who have also
registered to use the Services. You can deactivate the connection between the Services and
your Third-Party Account by contacting us using the contact information below or through your
account settings (if applicable). We will attempt to delete any information stored on our servers
that was obtained through such Third-Party Account, except the username and profile picture
that become associated with your account.

      </p>

      <h3 className={styles.heading3}>13. THIRD-PARTY WEBSITES AND CONTENTS</h3>
      <p className={styles.paragraph}>The Services may contain (or you may be sent via the App) links to other websites ('Third-Party
Websites') as well as articles, photographs, text, graphics, pictures, designs, music, sound,
video, information, applications, software, and other content or items belonging to or originating
from third parties ('Third-Party Content'). Such Third-Party Websites and Third-Party Content
are not investigated, monitored, or checked for accuracy, appropriateness, or completeness by
us, and we are not responsible for any Third-Party Websites accessed through the Services or
any Third-Party Content posted on, available through, or installed from the Services, including
the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of
or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or
permitting the use or installation of any Third-Party Websites or any Third-Party Content does
not imply approval or endorsement thereof by us. If you decide to leave the Services and
access the Third-Party Websites or to use or install any Third-Party Content, you do so at your
own risk, and you should be aware these Legal Terms no longer govern. You should review the
applicable terms and policies, including privacy and data gathering practices, of any website to
which you navigate from the Services or relating to any applications you use or install from the
Services. Any purchases you make through Third-Party Websites will be through other websites
and from other companies, and we take no responsibility whatsoever in relation to such
purchases which are exclusively between you and the applicable third party. You agree and
acknowledge that we do not endorse the products or services offered on Third-Party Websites
and you shall hold us blameless from any harm caused by your purchase of such products or
services. Additionally, you shall hold us blameless from any losses sustained by you or harm
caused to you relating to or resulting in any way from any Third-Party Content or any contact
with Third-Party Websites.

      </p>


      <h3 className={styles.heading3}>        
      14. ADVERTISERS
</h3>
      <p className={styles.paragraph}>
      We allow advertisers to display their advertisements and other information in certain areas of
the Services, such as sidebar advertisements or banner advertisements. We simply provide the
space to place such advertisements, and we have no other relationship with advertisers
        
      </p>

      
      <h3 className={styles.heading3}>15. SERVICES MANAGEMENT
      </h3>
      <p className={styles.paragraph}>
      We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal
Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law
or these Legal Terms, including without limitation, reporting such user to law enforcement authorities;
(3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or
disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in
our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise
disable all files and content that are excessive in size or are in any way burdensome to our systems;
and (5) otherwise manage the Services in a manner designed to protect our rights and property and
to facilitate the proper functioning of the Services.


      </p>

      <h3 className={styles.heading3}>16. PRIVACY POLICY
      </h3>
      <p className={styles.paragraph}>

      We care about data privacy and security. By using the Services, you agree to be bound by our
Privacy Policy posted on the Services, which is incorporated into these Legal Terms. Please be
advised the Services are hosted in India. If you access the Services from any other region of the
world with laws or other requirements governing personal data collection, use, or disclosure that
differ from applicable laws in India, then through your continued use of the Services, you are
transferring your data to India, and you expressly consent to have your data transferred to and
processed in India.
UniMaa Software Pvt Ltd ("Company," "we," "us," or "our") operates GuisedUp social media
application (the "App"). This Privacy Policy informs you of our policies regarding the collection,
use, and disclosure of personal data when you use our App.
Information Collection and Use:
We may collect several types of information when you use our App, including:
Personal Data: While using our App, we may ask you to provide us with certain personally
identifiable information that can be used to contact or identify you.
Usage Data: We may also collect information about how the App is accessed and used.
Use of Data:
We use the collected data for various purposes, including:
● To provide and maintain the App.
● To notify you about changes to our App.
● To provide customer support.
● To gather analysis or valuable information so that we can improve our App.
● To monitor the usage of our App.
Data Sharing
We may share your personal information in the following situations:
● With vendors, consultants, and other service providers who need access to such
information to carry out work on our behalf.
● With third parties to comply with legal obligations or to protect the rights, property, or
safety of the Company, our users, or others.
Artificial Intelligence Usage
We may utilize artificial intelligence algorithms to analyze user interactions with the App,
including but not limited to content preferences, engagement patterns, and behavior trends. This
analysis helps us enhance user experience, personalize content, and improve the performance
of the App.
Cookie Usage
We may use cookies and similar tracking technologies to collect information and improve our
services. Cookies are small files stored on your device that contain data about your browsing
activity. By using our App, you consent to the use of cookies and similar technologies for the
purposes described in this Privacy Policy.
Data Protection
We are committed to protecting your privacy and complying with applicable data protection laws,
including the General Data Protection Regulation (GDPR), the California Consumer Privacy Act
(CCPA), and the data protection laws of India[Digital Personal Data Protection Act, 2023]. We
implement appropriate technical and organizational measures to ensure the security and
confidentiality of your personal data.
User Rights
Depending on your jurisdiction, you may have certain rights regarding your personal data,
including the right to access, correct, or delete your personal information. Please contact us if
you wish to exercise any of these rights.
Changes to This Privacy Policy
We may update our Privacy Policy from time to time. You are advised to review this Privacy
Policy periodically for any changes.


      </p>

      <h3 className={styles.heading3}>17. COPYRIGHT INFRINGEMENTS
      </h3>
      <p className={styles.paragraph}>

      We respect the intellectual property rights of others. If you believe that any material available on
or through the Services infringes upon any copyright you own or control, please immediately
notify us using the contact information provided below (a 'Notification'). A copy of your
Notification will be sent to the person who posted or stored the material addressed in the
Notification. Please be advised that pursuant to applicable law you may be held liable for
damages if you make material misrepresentations in a Notification. Thus, if you are not sure that
material located on or linked to by the Services infringes your copyright, you should consider
first contacting an attorney.

      </p>

      <h3 className={styles.heading3}>18. TERM AND TERMINATION</h3>
<p className={styles.paragraph}>
These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT
LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT
TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO
AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION
FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN
THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR
ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
WITHOUT WARNING, IN OUR SOLE DISCRETION.
If we terminate or suspend your account for any reason, you are prohibited from registering and
creating a new account under your name, a fake or borrowed name, or the name of any third
party, even if you may be acting on behalf of the third party. In addition to terminating or
suspending your account, we reserve the right to take appropriate legal action, including without
limitation pursuing civil, criminal, and injunctive redress.
</p>

<h3 className={styles.heading3}>19. MODIFICATIONS AND INTERRUPTIONS
</h3>
<p className={styles.paragraph}>
We reserve the right to change, modify, or remove the contents of the Services at any time or for
any reason at our sole discretion without notice. However, we have no obligation to update any
information on our Services. We will not be liable to you or any third party for any modification,
price change, suspension, or discontinuance of the Services.
We cannot guarantee the Services will be available at all times. We may experience hardware,
software, or other problems or need to perform maintenance related to the Services, resulting in
interruptions, delays, or errors. We reserve the right to change, revise, update, suspend,
discontinue, or otherwise modify the Services at any time or for any reason without notice to
you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience
caused by your inability to access or use the Services during any downtime or discontinuance of
the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and
support the Services or to supply any corrections, updates, or releases in connection therewith.
</p>
<h3 className={styles.heading3}>20. GOVERNING LAW</h3>
<p className={styles.paragraph}>
These Legal Terms shall be governed by and defined following the laws of India. UNIMAA
SOFTWARE PRIVATE LIMITED and yourself irrevocably consent that the courts of India shall
have exclusive jurisdiction to resolve any dispute which may arise in connection with these
Legal Terms.

</p>
<h3 className={styles.heading3}>21. DISPUTE RESOLUTION</h3>
<p className={styles.paragraph}>

You agree to irrevocably submit all disputes related to these Legal Terms or the legal
relationship established by these Legal Terms to the jurisdiction of the India courts. UNIMAA
SOFTWARE PRIVATE LIMITED shall also maintain the right to bring proceedings as to the
substance of the matter in the courts of the country where you reside or, if these Legal
Terms are entered into in the course of your trade or profession, the state of your principal
place of business.

</p>
<h3 className={styles.heading3}>22. CORRECTIONS</h3>
<p className={styles.paragraph}>
There may be information on the Services that contains typographical errors, inaccuracies, or
omissions, including descriptions, pricing, availability, and various other information. We reserve the
right to correct any errors, inaccuracies, or omissions and to change or update the information on the
Services at any time, without prior notice.

</p>
<h3 className={styles.heading3}>23. DISCLAIMER
</h3>
<p className={styles.paragraph}>
THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE
THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST
EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES'
CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO
THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)
ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL
INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO
OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES,
TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED
AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE,
OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE,
OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE
FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT
OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
YOUR BEST JUDGEMENT AND EXERCISE CAUTION WHERE APPROPRIATE.

</p>
<h3 className={styles.heading3}>24. LIMITATIONS OF LIABILITY
</h3>
<p className={styles.paragraph}>
IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU
OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY,
INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE,
LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN
IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES
</p>
<h3 className={styles.heading3}>25. INDEMNIFICATION</h3>
<p className={styles.paragraph}>
You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and
all of our respective officers, agents, partners, and employees, from and against any loss,
damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made
by any third party due to or arising out of: (1) your Contributions; (2) use of the Services; (3)
breach of these Legal Terms; (4) any breach of your representations and warranties set forth in
these Legal Terms; (5) your violation of the rights of a third party, including but not limited to
intellectual property rights; or (6) any overt harmful act toward any other user of the Services
with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right,
at your expense, to assume the exclusive defence and control of any matter for which you are
required to indemnify us, and you agree to cooperate, at your expense, with our defence of such
claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding
which is subject to this indemnification upon becoming aware of it.

</p>
<h3 className={styles.heading3}>26. USER DATA</h3>
<p className={styles.paragraph}>
We will maintain certain data that you transmit to the Services for the purpose of managing the
performance of the Services, as well as data relating to your use of the Services. Although we
perform regular routine backups of data, you are solely responsible for all data that you transmit
or that relates to any activity you have undertaken using the Services. You agree that we shall
have no liability to you for any loss or corruption of any such data, and you hereby waive any
right of action against us arising from any such loss or corruption of such data.

</p>
<h3 className={styles.heading3}>27.DATA PROTECTION
</h3>
<p className={styles.paragraph}>
We are committed to protecting your privacy and complying with applicable data protection laws,
including the General Data Protection Regulation (GDPR), the California Consumer Privacy Act
(CCPA), and the data protection laws of India [Digital Personal Data Protection Act, 2023].
Please refer to our Privacy Policy for more information on how we collect, use, and protect your
personal data.
</p>

      <h3 className={styles.heading3}>28. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
SIGNATURES
</h3>
      <p className={styles.paragraph}>
      Visiting the Services, sending us emails, and completing online forms constitute electronic
communications. You consent to receive electronic communications, and you agree that all
agreements, notices, disclosures, and other communications we provide to you electronically,
via email and on the Services, satisfy any legal requirement that such communication be in
writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,
ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR
VIA THE SERVICES. You hereby waive any rights or requirements under any statutes,
regulations, rules, ordinances, or other laws in any jurisdiction which require an original
signature or delivery or retention of non-electronic records, or to payments or the granting of
credits by any means other than electronic means.
      </p>


      <h3 className={styles.heading3}>29. SMS TEXT MESSAGING</h3>
<p className={styles.paragraph}>
Opting Out
Not respond to it
Message and Data Rates
Please be aware that message and data rates may apply to any SMS messages sent or
received. The rates are determined by your carrier and the specifics of your mobile plan.
Support
If you have any questions or need assistance regarding our SMS communications, please
email us at prachidebnathjmk@gmail.com or call at +919774380128.

</p>


<h3 className={styles.heading3}>30. CALIFORNIA USERS AND RESIDENTS</h3>
<p className={styles.paragraph}>
If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance
Unit of the Division of Consumer Services of the California Department of Consumer Affairs in
writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
at (800) 952-5210 or (916) 445-1254.
</p>


<h3 className={styles.heading3}>31. CHANGES TO TERMS</h3>
<p className={styles.paragraph}>
We reserve the right to modify or replace these Terms at any time. It is your responsibility to
review these Terms periodically for changes. Your continued use of the App following the
posting of any changes to these Terms constitutes acceptance of those changes.

</p>


      <h3 className={styles.heading3}>32. MISCELLANEOUS
      </h3>
      <p className={styles.paragraph}>
      These Legal Terms and any policies or operating rules posted by us on the Services or in
respect to the Services constitute the entire agreement and understanding between you and us.
Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate
as a waiver of such right or provision. These Legal Terms operate to the fullest extent
permissible by law. We may assign any or all of our rights and obligations to others at any time.
We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any
cause beyond our reasonable control. If any provision or part of a provision of these Legal
Terms is determined to be unlawful, void, or unenforceable, that provision or part of the
provision is deemed severable from these Legal Terms and does not affect the validity and
enforceability of any remaining provisions. There is no joint venture, partnership, employment or
agency relationship created between you and us as a result of these Legal Terms or use of the
Services. You agree that these Legal Terms will not be construed against us by virtue of having
drafted them. You hereby waive any and all defences you may have based on the electronic
form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal
Terms.
      </p>
      <h3 className={styles.heading3}>33. CONTACT US
      </h3>
      <p className={styles.paragraph}>
      In order to resolve a complaint regarding the Services or to receive further information regarding
use of the Services, please contact us at:
UNIMAA SOFTWARE PRIVATE LIMITED
No.13-14, 2nd floor, Kothnur Main Rd, opposite Corporation Bank, JP Nagar 7th Phase,
Bengaluru, Karnataka 560078
Bengaluru, Karnataka 560078
India
Email-admin@guisedup.com

      </p>


  </div>
  </div>
  </main>
  );
};


