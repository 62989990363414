import React from 'react';
import { Why_Download_Question } from '../data/strings';

const WhyDownloadButton = ({whyDownloadClicked})=> {
  return (
     
    <div className="relative tracking-[0.2rem] font-normal mt-2 lg:mt-0" onClick={whyDownloadClicked}>
     <button className="bg-purple text-white rounded-md">
      <div className='flex items-center py-2 px-4'>
      <span className='pr-1 font-medium text-[16px] lg:text-3xl'>{Why_Download_Question}</span>
      </div>
        <div className='absoulte  w-full h-2  bg-lightRed rounded-bl-md rounded-br-md'></div>
      </button>
    </div>
  );
}

export default WhyDownloadButton;