import React, { useState } from 'react';
import { APPSTORE_LINK, PLAYSTORE_LINK } from '../data/strings';

export default function DownloadButton() {

  const [showPopup, setShowPopup] = useState(false);

  const handleDownloadClick = () => {
    setShowPopup(!showPopup);
  };

  const handleDownloadHover = () => {
    setShowPopup(true);
  };

  const handleAndroidClick = () => {
    // Open the Play Store link in a new window
    window.open(PLAYSTORE_LINK, "_blank");
    console.log('Android download clicked');
    setShowPopup(false);
  };
  
  const handleIOSClick = () => {
    // Open the App Store link in a new window
    window.open(APPSTORE_LINK, "_blank");
    console.log('iOS download clicked');
    setShowPopup(false);
  };
  
/*
  const handleClosePopup = () => {
    setShowPopup(false);
  };

*/

  return (
    <div className="tracking-[0.2rem] font-normal relative">
      <button className="relative bg-blue hover:bg-blue text-white rounded-md"
        onClick={handleDownloadClick}
        onMouseEnter={handleDownloadHover}
      >
        <div className='flex items-center py-2 px-4'>
        <span className='pr-1 font-medium text-[16px] lg:text-3xl'>Download now</span>
        <svg
          className="h-5 w-5 lg:h-9 lg:w-9"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
        </div>
        <div className='absoulte  w-full h-2  bg-yellow rounded-bl-md rounded-br-md'></div>
      </button>

      {showPopup && (
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-4 bg-white bg-opacity-40 backdrop-filter backdrop-blur-lg rounded-xl p-4 w-64 z-10">
        <div className="flex items-center justify-center gap-4">
          <a
            href="/"
            className="flex items-center bg-yellow hover:bg-green-700 text-black font-bold py-2 px-4 rounded"
            onClick={handleAndroidClick}
          >
            Android
          </a>
          <a
            href="/"
            className="flex items-center bg-yellow hover:bg-green-700 text-black font-bold py-2 px-4 rounded"
            onClick={handleIOSClick}
          >
            Iphone
          </a>
        </div>
        {/* <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={handleClosePopup}
        >
          &times;
        </button> */}
      </div>   
      )}

    </div>
  );
}

