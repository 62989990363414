// PopupVideo.js
import React, { useEffect, useState } from 'react';

const PopupVideo = ({ onClose }) => {
  const audioRef = React.useRef(null); // Reference for the audio element


  const gifNames = ['boss_firing', 'getting_bullied_college', 'always_compared',
    'feeling_left_out', 'overthinking', 'getting_fit', 'tired_of_being_second_choice',
     'hustle_to_be_best', 'heartbreak', 'alone_chronic_issue', 
     'no_time_selfcare', 
     'wanna_share_achievement', 
     'no_time_for_yourself',
    ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? gifNames.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    if (currentIndex === gifNames.length-1){
      
      onClose(); // close the popup when its the end
    }else{
      setCurrentIndex((prevIndex) => (prevIndex === gifNames.length - 1 ? 0 : prevIndex + 1));
    }    
  };


  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3800);

    return () => clearInterval(interval);
  }, [handleNext]);


  
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.play(); // Play the audio when the component mounts
    }
  }, []);

  return (
    <main>

<audio ref={audioRef} loop>
        <source src="/bg_music.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>


<div className="fade-in w-full  bg-black bg-opacity-20 backdrop-filter backdrop-blur-lg px-4 lg:px-8 mt-6 rounded-xl pb-4 pt-2"
//  onMouseLeave={onClose}
 >
      <div className="relative">
        <div className='w-full flex items-center justify-end'> <span className='text-xl lg:text-3xl text-white mb-1 lg:mb-2 cursor-pointer lg:py-2' onClick={onClose}>X</span> </div>
        <img 
          src={`/gifs/${gifNames[currentIndex]}.gif`} 
          className="w-full lg:w-[1200px] h-auto bg-white rounded-md"
        />
        <div className="absolute top-1/2 left-4 transform -translate-y-1/2">
          <button
            className="bg-white rounded-full p-2 shadow-md hover:bg-gray-200 focus:outline-none mt-[60px]"
            onClick={handlePrevious}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 lg:h-6 lg:w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        </div>
        <div className="absolute top-1/2 right-4 transform -translate-y-1/2">
          <button
            className="bg-white rounded-full p-2 shadow-md hover:bg-gray-200 focus:outline-none mt-[60px]"
            onClick={handleNext}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 lg:h-6 lg:w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        {gifNames.map((_, index) => (
          <div
            key={index}
            className={`w-1 h-1 lg:w-3 lg:h-3 rounded-full mx-1 ${
              index === currentIndex ? 'bg-white' : 'bg-gray-400'
            }`}
          />
        ))}
      </div>
    </div>
    </main>
  );
};

export default PopupVideo;
