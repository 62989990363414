// Import the necessary Firebase modules
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyATseZpuzmWm68wV4YwPVmOQSnrVl5ifbE",
  authDomain: "hue-app-1e5d6.firebaseapp.com",
  projectId: "hue-app-1e5d6",
  storageBucket: "hue-app-1e5d6.appspot.com",
  messagingSenderId: "713073225148",
  appId: "1:713073225148:web:c97fce7013270788250fb9",
  measurementId: "G-HD4610YV9S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const writeDataToFirestore = async (data) => {
    try {
        const docRef = await addDoc(collection(db, "messages"), data);
        // console.log("Document written with ID: ", docRef.id);
        return true;
    } catch (e) {
        console.error("Error adding document: ", e);
        return false;
    }
};
