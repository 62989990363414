export const NAME = "Guised Up";
export const HEADING_HERO_SECTION = "Connect with those who understand your journey";
export const Why_Download_Question = "Why download this app?";
export const FEEDBACKS_SECTION_HEADING = "Feedback from Our Users";


export const PLAYSTORE_LINK = "https://play.google.com/store/apps/details?id=com.hue";
export const APPSTORE_LINK = "https://apps.apple.com/us/app/guised-up/id6618147177";

// Founders words
export const FOUNDER_IMAGE = 'logo.jpg';
export const FOUNDER_NAME = 'Guised Up Founder';
export const FOUNDER_HEADING = 'Connect with those who understand your journey';
export const FOUNDER_PARAGRAPH = "I have come across situations in life where sadly I could not find anyone who could perceive my journey.\nI was being bullied everyday, made to feel left out on purpose…..and there was not a single help whom I could speak up to !!\nAt that point in life, I needed a true community, a group with whom I could share my feelings,… but no one was there !!!\nSo, I gave it a thought that if I feel this way,despite having so many people around me, there must be other people too who feel the same void around them !!\nAnd for obvious reasons, only someone who has also gone through similar situation in life would understand you better, if not best !!\nHence I came up with the idea of “Guised Up” for every single person who has ever felt this blank space like me, and every single person who need to find their true community, their true group in this small and known yet large and unknown damn world!!";
